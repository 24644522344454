a{
    text-decoration: none;
    color: white;
    font-weight: bold;
}

a:hover{
    color: #FFF1D0;
}

body{
    padding-bottom: 100px;
}

.img-print{
    max-width: 100%;
    width: 250px;
    display: none;
}

@media print {
    .img-print{
        display: inline-block;
    }
}
.button{
    background-color: #FFF1D0;
    padding: 40px 0;
    font-weight: bold;
    cursor: pointer;
    border-radius: 2px;
}

.button:hover{
    background-color: #F0C808;
}